import React from 'react';

export default function BlogListItem({ item }) {
  const { title, tags, date, id } = item;
  return (
    <div>
      <div>
        <p className='text-gray-900 text-sm font-bold mb-2'>{date} </p>
        <a
          className="text-3xl font-bold mb-2 hover:underline hover:text-blue-500"
          href={`https://blog.trevorlane.dev/post/${id}`}
        > {title} </a>
      </div>
      {tags.map(tag => <span className="inline-block  pr-3 text-sm font-semibold text-blue-700 mr-2">{tag}</span>)}
    </div >
  )
}