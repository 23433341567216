
import { useState, useEffect } from 'react'
import Head from './Head'
import BlogListItem from './BlogListItem'
import { getPosts } from './services/gistService'
import Pill from './Pill'
import _ from 'lodash'


const test_post = {
  title: 'Test Title',
  tags: ['test1', 'test2'],
  date: '12/25/23',
  id: 'test_id'
}
const test_posts = [
  test_post,
  test_post,
  test_post,
  test_post,
  test_post,
  test_post,
  test_post,
]

export default function Layout() {

  const url = 'https://api.github.com/gists'
  const [posts, setPosts] = useState([])
  const [tags, setTags] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedPosts, setSelectedPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [xy, setXY] = useState([0, 0])
  const gistListId = '3797a38c4fb8b5a65f24aac2631eb3ed'
  const [isWebGLSupported, setIsWebGLSupported] = useState(true);

  const updateSelectedTags = (e, tag) => {
    // Update the tags with the toggled selection state
    const updatedTags = tags.map(t => t.tag === tag.tag ? { ...t, selected: !t.selected } : t);

    // Sort the updated tags in alphabetical order
    const sortedUpdatedTags = updatedTags.sort(); // this is borked, needs fix

    setTags(sortedUpdatedTags);

    // Get the names of tags where selected is true and convert them to lower case
    const selectedTagNames = sortedUpdatedTags.filter(t => t.selected).map(t => t.tag.toLowerCase());

    // Determine the posts to be selected
    const updatedSelectedPosts = selectedTagNames.length > 0
      ? posts.filter(post => post.tags.some(tag => selectedTagNames.includes(tag.toLowerCase())))
      : posts; // If no tags are selected, include all posts

    // Update selected posts
    setSelectedPosts(updatedSelectedPosts);
  }
  const populateTags = (posts) => {
    // Collect all tags from posts, convert them to lowercase, and ensure uniqueness
    const uniqueTagNames = new Set(
      _.flatten(posts.map(post => post.tags.map(tag => tag.toLowerCase())))
    );

    // Map the unique tag names to objects with the structure { tag, selected: false }
    const uniqueTags = Array.from(uniqueTagNames).map(tagName => ({ tag: tagName, selected: false }));

    setTags(uniqueTags);
    console.log({ tags: uniqueTags });
  }

  useEffect(() => {
    // Check for WebGL support
    async function fetchPosts() {
      try {
        await getPosts(url, gistListId, setIsLoading)
          .then(response => {
            if (!response.posts) throw new Error('No posts found')
            console.log({ response })
            setPosts([...response.posts])
            setSelectedPosts([...response.posts])
            populateTags(response.posts)
          })

      } catch (error) {
        console.log(error)
        console.log(test_posts)
        setPosts([...test_posts])
        setSelectedPosts([...test_posts])
        populateTags([...test_posts])
      }
    }

    fetchPosts()
  }, [])

  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:flex md:justify-between lg:px-8 lg:justify-end">
        <Pill />

        <div className="w-full lg:h-100 lg:w-1/2 lg:right-1/2 lg:fixed lg:top-0 lg:bottom-0">
          <div className="h-48 lg:h-full lg:-ml-8 xl:ml-0"> {/* Adjusted the height for mobile */}
            <Head
              xy={xy}
              setXY={setXY}
              setWebGLSupported={setIsWebGLSupported}
              isWebGLSupported={isWebGLSupported}
            />
          </div>
          <p className='absolute bottom-0 left-1 text-sm font-bold'>X: {xy[0]} Y:{xy[1]}</p>
        </div>
        <div className="px-6 mt-16 lg:mt-0 lg:contents overflow-y-auto"> {/* Added margin-top for mobile */}
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 lg:w-1/2">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">trevorlane.dev</h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Welcome to my online space. I hope you find what you're looking for.
            </p>
            <div className="mt-4 mx-auto">
              {
                tags.length && tags.map(tag => (
                  <p
                    onClick={e => updateSelectedTags(e, tag)}
                    className={`inline-block text-xs  hover:underline hover:text-red-600 hover:text-lg ${tag.selected ? 'font-bold italic' : ''}`}
                  >
                    {tag.tag},
                  </p>
                ))
              }
            </div>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                {selectedPosts.map(post => {
                  return (
                    <li className="flex gap-x-3">
                      <BlogListItem item={post} />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
