import React, { useState, useEffect } from 'react';

const Pill = () => {
  const [scrollPercentage, setScrollPercentage] = useState('00.00');

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - windowHeight;
    const scrollPosition = (scrollTop / totalDocScrollLength) * 100;
    const formattedScrollPosition = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 2
    }).format(scrollPosition);

    setScrollPercentage(formattedScrollPosition);
  };

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", calculateScrollDistance);

    return () => {
      window.removeEventListener("scroll", calculateScrollDistance);
    };
  });

  const renderPercentageSpan = (percentage) => {
    // Assuming each digit takes up approximately 20% of the width
    const digitWidthPercent = 10;
    const number = Number(percentage.replace('%', ''))

    return percentage.split('').map((char, index) => {
      // Determine if this character should be white based on its position
      const isWhite = ((index + 1) * digitWidthPercent) <= Math.floor(number);
      console.log((index + 1) * digitWidthPercent, Math.floor(number))
      return (
        <span key={index} className={isWhite ? 'text-white' : 'text-black'}>
          {char}
        </span>
      );
    });
  };

  const renderPercentageSpans = (percentage) => {
    // Convert percentage string to a number
    const number = parseFloat(percentage);

    // Define the threshold for each character including the percentage sign
    // Assuming there are 5 characters plus the percentage sign, each character takes up about 16.66% of the bar's width
    const percentagePerCharacter = [8, 16.66, 33.32, 50, 66.66, 83.32];

    // Including the percentage sign in the characters array
    const characters = percentage.split('').concat('%');

    return characters.map((char, index) => {
      // Check if the filled percentage surpasses the threshold for this character.
      const isWhite = number >= percentagePerCharacter[index];

      return (
        <span key={index} className={isWhite ? 'text-white font-bold text-sm' : 'text-black'}>
          {char}
        </span>
      );
    });
  };



  return (
    <div className="fixed top-4 right-5">
      <div className="rounded-full bg-white h-6 border border-black  ">
        <div
          className={` d rounded-tl-full rounded-bl-full bg-black h-full " ${scrollPercentage > 99 ? "rounded-tr-full rounded-br-full" : ""}`}
          style={{ width: `${(scrollPercentage < 99.99 ? scrollPercentage : 99.99)}%` }}
        >
          <div className="flex items-center font-bold text-sm  px-2">
            <p className='text-center'>
              {renderPercentageSpans(`${(scrollPercentage) < 99.99 ? scrollPercentage : 99.99}`)}

            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Pill;
