import { useEffect, useRef } from "react";
import './App.css';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

function Head({ xy, setXY, isWebGLSupported, setWebGLSupported }) {

  const isWebGLAvailable = () => {
    try {
      const canvas = document.createElement("canvas");
      return !!(window.WebGLRenderingContext && (canvas.getContext("webgl") || canvas.getContext("experimental-webgl")));
    } catch (e) {
      return false;
    }
  };

  const refContainer = useRef(null);
  useEffect(() => {
    if (!isWebGLAvailable()) {
      setWebGLSupported(false);
      return;
    }
    // === THREE.JS CODE START ===
    var scene = new THREE.Scene();
    var renderer = new THREE.WebGLRenderer();
    refContainer.current && refContainer.current.appendChild(renderer.domElement);
    const containerWidth = refContainer.current.clientWidth;
    const containerHeight = refContainer.current.clientHeight;
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    renderer.setSize(containerWidth, containerHeight);
    var camera = new THREE.PerspectiveCamera(75, containerWidth / containerHeight, 0.1, 1000);


    const light1 = new THREE.DirectionalLight(0xffffff, 2);
    light1.position.set(10, 10, 5);
    scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 1);
    light2.position.set(-10, -10, -5);
    scene.add(light2);

    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    const loader = new GLTFLoader();

    let head = null;
    loader.load('/poly.glb', function (gltf) {
      head = gltf.scene;
      scene.background = new THREE.Color(0xFFF); // Set to any color distinct from your model.
      head.rotation.y = Math.PI / 1.8;
      head.rotation.x = .1;
      scene.add(gltf.scene);
      var animate = function () {
        requestAnimationFrame(animate);
        head.rotation.y += 0.;
        renderer.render(scene, camera);
      };
      animate();

    }, undefined, function (error) {


      console.error(error);
    });
    camera.position.z = 1;

    // Mouse movement integration
    let lastMouseX = 0;
    let lastMouseY = 0;
    let lastTouchX = null;
    let lastTouchY = null;
    const rotationFactor = isMobile ? 0.02 : 0.005;  // Adjust the values as needed

    const handleMovement = (clientX, clientY, isTouchEvent = false) => {
      let deltaX = 0;
      let deltaY = 0;

      if (isTouchEvent && lastTouchX !== null && lastTouchY !== null) {
        deltaX = clientX - lastTouchX;
        deltaY = clientY - lastTouchY;
      } else {
        deltaX = clientX - lastMouseX;
        deltaY = clientY - lastMouseY;
      }

      if (head) {
        head.rotation.y += deltaX * rotationFactor;
        head.rotation.x += deltaY * rotationFactor;
      }

      if (isTouchEvent) {
        lastTouchX = clientX;
        lastTouchY = clientY;
      } else {
        lastMouseX = clientX;
        lastMouseY = clientY;
      }

      setXY([clientX, clientY]);
    };



    const onMouseMove = (event) => {
      handleMovement(event.clientX, event.clientY);
    };

    const onTouchStart = (event) => {
      const touch = event.touches[0];
      lastTouchX = touch.clientX;
      lastTouchY = touch.clientY;
    };

    const onTouchMove = (event) => {
      event.preventDefault();

      if (refContainer.current.contains(event.target)) {
        const touch = event.touches[0];
        const deltaX = touch.clientX - lastTouchX;
        const deltaY = touch.clientY - lastTouchY;

        if (head) {
          head.rotation.y += deltaX * rotationFactor;
          head.rotation.x += deltaY * rotationFactor;
        }

        lastTouchX = touch.clientX;
        lastTouchY = touch.clientY;
      }
    };





    const onWindowResize = () => {
      const containerWidth = refContainer.current.clientWidth;
      const containerHeight = refContainer.current.clientHeight;
      renderer.setSize(containerWidth, containerHeight);
      camera.aspect = containerWidth / containerHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('resize', onWindowResize);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchstart', onTouchStart);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchstart', onTouchStart);

      refContainer.current && refContainer.current.removeChild(renderer.domElement);
    };

  }, []);

  return (
    <>
      {isWebGLSupported
        ? <div className="h-full w-full" ref={refContainer} ></div>
        : <div className="flex justify-center items-center h-full">
          <a href='https://letmegooglethat.com/?q=enable+webGL' className="text-red-500 text-xl animate-spin-horizontal hover:underline">enable webGL for full site experience</a>
        </div>}
    </>
  );
}

export default Head;
